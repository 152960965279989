/* eslint-disable @typescript-eslint/no-var-requires */

const isNode = require('detect-node');
const { initReactI18next } = require('react-i18next');
const i18nConfig = require('./config');
const i18n = isNode ? require('i18next') : require('i18next').default;

const localeRedirectsMap = new Map(i18nConfig.localeRedirects);

const isCrowdinInContext = process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT;
const crowdinInContextLng = 'ach-UG';

const createIsomorphicI18next = (locale) => {
  if (i18n.isInitialized) {
    return { i18n, promise: Promise.resolve() };
  }

  const backends = [];
  const backendOptions = [];

  if (isNode) {
    const FSBackend = require('i18next-fs-backend');
    const ChainedBackend = require('i18next-chained-backend');
    const fsBackendOptions = {
      loadPath: (language, namespace) => {
        const usedLanguage = (() => {
          if (isCrowdinInContext) {
            return crowdinInContextLng;
          }

          return localeRedirectsMap.get(language) || language;
        })();

        return `${i18nConfig.localesPath}/${usedLanguage}/${namespace}.json`;
      },
    };

    backendOptions.push(fsBackendOptions);
    backends.push(FSBackend);

    i18n.use(ChainedBackend);
  } else {
    const I18nextBrowserLanguageDetector = require('i18next-browser-languagedetector').default;
    const ChainedBackend = require('i18next-chained-backend').default;
    const HttpBackend = require('i18next-http-backend');
    const httpBackendOptions = {
      loadPath: [
        // for http requests the `public/` directory is omitted
        i18nConfig.localesPath.replace('public/', '/'),
        isCrowdinInContext ? crowdinInContextLng : '{{lng}}',
        '{{ns}}.json',
      ].join('/'),
    };

    backendOptions.push(httpBackendOptions);
    backends.push(HttpBackend);

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    i18n.use(I18nextBrowserLanguageDetector).use(ChainedBackend);
  }

  const promise = i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: [], // by not adding fallbackLng it prevents fetching same texts as default ones
      preload: [locale],
      lng: locale,

      // with this it will only load the translation files for the specific
      // locale (e.g. de-DE) and not the general one (e.g. de)
      load: 'currentOnly',

      debug: false,
      saveMissing: false,
      keySeparator: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        backends,
        backendOptions,
      },
      defaultNS: i18nConfig.defaultNamespaces[0],
      ns: i18nConfig.defaultNamespaces,
      react: {
        // https://react.i18next.com/latest/i18next-instance
        bindI18n: 'languageChanged',
        useSuspense: false,
      },
    });

  return { i18n, promise };
};

module.exports = createIsomorphicI18next;

import createIsomorphicI18next from 'i18n';
import getServerSideLocale from 'i18n/get-server-side-locale';
import type { i18n } from 'i18next';
import { setAutoFreeze } from 'immer';
import App from 'next/app';
import { AppContext } from 'next/dist/pages/_app';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { getSession } from '@bridebook/toolbox/src/api/auth/with-session';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { FelaProvider } from '@bridebook/ui';
import * as Sentry from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withDevice } from 'lib/api/with-device';
import { env } from 'lib/env';

// Create a client
const queryClient = new QueryClient();

// With immer 8.0.0 release, "freeze" happens always by default
// (this means not only in dev but also in production)
// Setting the old "freeze" behavior to play safe in production
// https://github.com/immerjs/immer/pull/702
setAutoFreeze(process.env.NODE_ENV !== 'production');

const wasI18nSerialized = (i18nServer: any) => (i18nServer ? !i18nServer.services : false);

export default class MyApp extends App<{ renderer: any }, { language: string; i18n: i18n }> {
  static async getInitialProps(appContext: AppContext) {
    const { Component } = appContext;
    let pageProps: Record<string, any> = {};
    let serverLanguage;

    if (typeof window === 'undefined') {
      const enhancedContext = await withDevice(appContext.ctx);
      const session = await getSession(
        enhancedContext.req,
        enhancedContext.res as NonNullable<typeof enhancedContext.res>,
      );

      /* i18next */
      const serverUser = session.user?.id ?? null;
      const userLocale = await getServerSideLocale(serverUser);
      serverLanguage =
        userLocale || enhancedContext.req?.device?.serverLocale || enhancedContext.req?.language;
      const i18nInstance = await (async () => {
        const { promise, i18n } = createIsomorphicI18next(serverLanguage);
        await promise;
        return i18n;
      })();

      i18nInstance.changeLanguage(userLocale || enhancedContext.req?.device?.serverLocale);

      pageProps.i18n = i18nInstance;
    }
    if (Component.getInitialProps) {
      const componentPageProps = await Component.getInitialProps(appContext.ctx);
      pageProps = {
        ...pageProps,
        ...componentPageProps,
      };
    }

    return {
      pageProps: {
        ...pageProps,
        language: serverLanguage || gazetteer.getMarketByCountry(CountryCodes.GB).locale,
      },
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error, (scope) => {
      scope.setTag('severity', 'critical');
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      return scope;
    });

    // This is needed to render errors correctly in development / production
    super.componentDidCatch?.(error, errorInfo);
  }

  render() {
    const { Component, pageProps, renderer } = this.props;
    const { language, i18n: i18nServer } = pageProps;
    const i18nInstance =
      !i18nServer || wasI18nSerialized(i18nServer)
        ? createIsomorphicI18next(language).i18n
        : i18nServer;

    const showReactQueryDevtools = Object.keys(this.props.router.query).includes('reactQuery');

    return (
      <I18nextProvider i18n={i18nInstance}>
        <QueryClientProvider client={queryClient}>
          <FelaProvider renderer={renderer}>
            <Component {...pageProps} />
          </FelaProvider>
          {!env.IS_PRODUCTION_BUILD && showReactQueryDevtools && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </I18nextProvider>
    );
  }
}

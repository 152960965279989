import * as clm from 'country-locale-map';
import i18nConfig from 'i18n/config';
import { NextPageContext } from 'next';
import uuid from 'uuid-random';
import { getUserAgent } from '@bridebook/toolbox/src/api/get-user-agent';
import { getRemoteAddress } from '@bridebook/toolbox/src/api/getRemoteAddress';
import { E2E_TESTING_COOKIE } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getCountryFromHeaders } from '@bridebook/toolbox/src/getCountryFromHeaders';
import { CustomPageContext, Device } from 'lib/types';

const getAnalyticsContext = (req: CustomPageContext['req']) => {
  const protocol = req.headers['x-forwarded-proto'];
  const referer = req.headers['referer'];
  const ip = getRemoteAddress(req);
  const host = `${protocol}://${req.headers.host}`;
  const url = `${host}`;
  const page = {
    path: '',
    referrer: referer,
    search: '',
    title: '',
    url,
  };
  return { ip, page, userAgent: req.userAgent };
};

export async function withDevice(ctx: NextPageContext): Promise<CustomPageContext> {
  if (process.browser) {
    throw new Error('withDevice can run only in server side context');
  }
  const customPageContext: CustomPageContext = ctx as CustomPageContext;
  const req = customPageContext.req;
  const userAgent = getUserAgent(req);
  req.userAgent = userAgent;
  const { isMobile, isTablet, isSafari, isiPhone, isChrome, isIE, isChrome41 } = userAgent;
  const host = 'business.bridebook.com';
  const isMobileSafari = (isMobile && isSafari) || (isiPhone && !isChrome);
  const isDesktop = !isMobile && !isTablet;
  const analyticsContext = getAnalyticsContext(req);
  let countryCode = getCountryFromHeaders(req.headers, CountryCodes.GB);
  // @ts-ignore-error:
  if (req?.cookies?.[E2E_TESTING_COOKIE] === 'true') {
    countryCode = CountryCodes.GB;
  }
  const serverLocaleByIP = clm.getLocaleByAlpha2(countryCode) || 'en-GB';
  const detectedLocale = serverLocaleByIP.replace('_', '-');
  const serverLocale =
    i18nConfig.supportedLanguages.includes(detectedLocale) ||
    i18nConfig.supportedLanguages.includes(detectedLocale.split('-')[0])
      ? detectedLocale
      : 'en-GB';

  const device: Device = {
    isMobileUA: isTablet ? false : isMobile,
    isTabletUA: isTablet,
    isMobile: isTablet ? false : isMobile,

    isLiveOnCDN: Boolean(req.headers && req.headers['x-amz-cf-id']), // FIXME: should be removed
    isIE,
    isChrome41,
    isTablet,
    isDesktop,
    isDesktopDesign: isDesktop,
    host,
    width: 0,
    height: 0,
    isMobileSafari,
    sessionId: uuid(),
    analyticsContext,
    countryCode,
    serverLocale,
  };

  req.device = device;

  return customPageContext;
}

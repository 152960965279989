const isLive = process.env.DATABASE === 'production';

// List of redirects, with the shape [sourceLocale, targetLocale]. It is to
// avoid requiring double requests - for example without this, with de-CH
// locale it would have to request de-CH (which would give 404) and de (would
// need redirect)
const localeRedirects = [
  ['de', 'de-DE'],
  ['de-AT', 'de-DE'],
  ['de-CH', 'de-DE'],
];

// Although this seems not doing anything, it is required to have the
// locale files available in Vercel functions
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('path').resolve('public/static/locales');

const i18nConfig = {
  supportedLanguages: ['en-GB', 'en-US', 'de-DE', 'de-AT', 'de-CH', 'fr-FR', 'en-IE'].concat(
    isLive ? [] : [],
  ),
  defaultNamespaces: [
    'common',
    'navigation',
    'featuresTour',
    'profileSettings',
    'ui',
    'editor',
    'navMenu',
    'steps',
    'venuePro',
    'pay-per-enquiry',
    'badDebt',
  ],
  localesPath: 'public/static/locales',
  localeRedirects,
};

module.exports = i18nConfig;

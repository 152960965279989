import { SupplierUsers } from '@bridebook/models';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import i18nConfig from '../i18n/config';
import { ApiEndpoint } from '../lib/api/api-endpoints';

/*
 * Get User's l10n object first and use locale string from there,
 * If it is missing, get locale from user's IP addresss countryCode detection
 */
const getServerSideLocale = async (userId: string | null) => {
  const userLocale = await getUserLocale(userId).catch((err) => {
    console.error(err);
    return null;
  });

  const serverLocale =
    userLocale &&
    (i18nConfig.supportedLanguages.includes(userLocale) ||
      i18nConfig.supportedLanguages.includes(userLocale.split('-')[0]))
      ? userLocale
      : '';

  return serverLocale;
};

async function getUserLocale(userId: string | null): Promise<string | null> {
  if (!userId) {
    return null;
  }
  if (typeof window === 'undefined') {
    const { runInFirebaseContext } = await import('@bridebook/toolbox/src/api/firebase-client');
    const user = await runInFirebaseContext(userId, () => SupplierUsers._.getById(userId).get());
    return user?.l10n?.locale || null;
  } else {
    const res = await authenticatedFetch(ApiEndpoint.user.locale);
    if (res.status === 200) {
      const responsePayload = await res.json();
      return (responsePayload && responsePayload.locale) || null;
    }
  }
  return null;
}
export default getServerSideLocale;
